import { mobile, styled } from '@obvio/app'

export const Content = styled.div`
  > div {
    ${(theme) => theme.flexCenter}
  }
  overflow: auto;
  color: ${(theme) => theme.colors.secondary};
  width: 100%;
`

export const Block = styled.div`
  height: 100%;
  width: 5rem;
  background-color: #848381;
  @media ${mobile} {
    display: none;
  }
`

export const Wrap = styled.div`
  z-index: 51;
  position: fixed;
  inset: 0;
  background: ${(theme) => theme.colors.complementary.semilight};
  color: ${(theme) => theme.colors.secondary};
  display: flex;
  text-align: center;
  @media ${mobile} {
    flex-direction: column;
  }
`
