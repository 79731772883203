import { styled, Text } from '@obvio/app'
import { CheckboxInput, Grid, GridItem, Submit } from '@obvio/ui'

export const GridItemMax = styled(GridItem)`
  grid-column: span 2;
  > input {
    width: 100%;
  }
`

export const SignTitle = styled(Text)`
  font-size: 1rem;
  font-weight: 500;
`

export const Title = styled(Text)`
  font-size: 3.125rem;
  font-weight: 300;
  font-family: ${(theme) => theme.secondaryFont};
  line-height: 135%;
  > b {
    font-weight: 300;
    font-family: ${(theme) => theme.font};
  }
  display: flex;
  flex-direction: column;
`

export const FormGrid = styled(Grid)`
  width: 39.875rem;
`

export const StyledSubmit = styled(Submit)`
  color: ${(theme) => theme.colors.secondary};
  font-size: 2.25rem;
  font-family: ${(theme) => theme.secondaryFont};
  font-weight: 300;
  > div {
    align-items: center;
  }
`

export const StyledCheckboxInput = styled(CheckboxInput)`
  > span {
    margin-left: ${(theme) => theme.spacing.large};
    text-align: left;
    font-size: 0.75rem;
    color: ${(theme) => theme.colors.secondary.alpha(0.65)};
    position: static;
  }
`
