import { NavFormConfirmed } from './NavFormConfirmed'
import { MaxFadeIn } from './NavFormEvent/components'

import type { AllowUndefinedForOptional } from '@obvio/utils'
import type { ReactElement } from 'react'

type NavFormWithConfirmProps = AllowUndefinedForOptional<{
  confirmed: boolean
  children: ReactElement
  text?: string
}>

export function NavFormWithConfirm({
  confirmed,
  children,
  text,
}: NavFormWithConfirmProps): ReactElement {
  if (confirmed) {
    return (
      <MaxFadeIn>
        <NavFormConfirmed text={text} />
      </MaxFadeIn>
    )
  }
  return children
}
