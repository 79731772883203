import { styled } from '@obvio/app'
import { RichText } from '@obvio/template'
import { Stack } from '@obvio/ui'

import { CenteredSection, MaxWidthContent } from '../CenteredSection'
import { ReadMoreLink } from '@/components/ReadMoreLink'

import type { AllowUndefinedForOptional } from '@obvio/utils'
import type { ReactElement } from 'react'

type CTASectionProps = AllowUndefinedForOptional<{
  description: string
  hrefText?: string
  href?: string
}>

export const ReadMoreMargin = styled(ReadMoreLink)`
  margin-top: ${(theme) => theme.spacing.extraLarge} !important;
`

const CenteredText = styled(CenteredSection)`
  a {
    justify-content: center;
  }
`

export function CTASection({
  description,
  hrefText,
  href,
}: CTASectionProps): ReactElement {
  return (
    <CenteredText>
      <MaxWidthContent>
        <Stack kind="vertical">
          <RichText value={description} />
          {href && hrefText ? (
            <ReadMoreMargin href={href}>{hrefText}</ReadMoreMargin>
          ) : null}
        </Stack>
      </MaxWidthContent>
    </CenteredText>
  )
}
