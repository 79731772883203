import {
  AssetsProvider,
  Context,
  styled,
  useMutation,
  useQuery,
  useRouter,
  useTranslation,
} from '@obvio/app'
import { RichText } from '@obvio/template'
import { Form, HideScroll, Stack, TextInput, toast } from '@obvio/ui'
import { useCallback, useRef, useState } from 'react'

import { Block } from './NavForm/components'
import {
  BackButton,
  ContentAlign,
  Deadline,
  Divider,
  ExpandMorePanelColor,
  FormContent,
  FullWidthGrid,
  MaxFadeIn,
  MinHeightImageStyle,
  StackCenter,
  WrapColor,
} from './NavFormEvent/components'
import { SignTitle, Title } from './NavFormRestaurant/components'
import { StyledCheckboxInput, StyledSubmit } from './NavFormSpa/components'
import { NavFormWithConfirm } from './NavFormWithConfirm'
import { Arrow } from '../Arrow'
import { ExpandMorePanel } from '../Nav/NavExpandMorePanel'
import { ReadMoreMargin } from '../Renderer/RendererVariants/CTASection'

import type { ReactElement } from 'react'

const Scroll = styled(HideScroll)`
  > div {
    height: 100%;
  }
`

type GuestFormData = {
  email: string
  name: string
  phone: string
}

export function NavFormEvent(): ReactElement | null {
  const [confirmed, setConfirmed] = useState(false)
  const [infoOpened, setInfoOpened] = useState(true)
  const [registerGuest] = useMutation('registerGuest')
  const scrollRef = useRef<HTMLDivElement>(null)

  const { hasParam, removeParam, query } = useRouter()

  const { t } = useTranslation()

  const { id: slug } = query

  const open = hasParam('id')

  const { data } = useQuery('getEvent', {
    args: slug ? { slug } : null,
    withoutArgs: open ? 'IGNORE' : 'WAIT',
    select: {
      title: true,
      category: {
        title: true,
      },
      date: true,
      images: true,
      description: true,
      startTime: true,
    },
  })

  const handleSubmit = useCallback(
    async ({ email, name, phone }: GuestFormData) => {
      if (data?.id) {
        await registerGuest({
          email,
          name,
          phone,
          eventId: data.id,
        })

        setConfirmed(true)
        toast.success(t('eventSignup.form.success'))
      }
    },
    [data?.id, registerGuest, t],
  )

  const image = data?.images[0]
  const day = data?.date.getDate() ?? ''
  const month = data?.date.toLocaleString('default', { month: 'short' }) ?? ''

  const date = `${day} ${month}`

  return (
    <Context name="navForm">
      <WrapColor style={{ display: open ? 'flex' : 'none' }}>
        {open && (
          <>
            <Block />
            <ExpandMorePanelColor
              onClick={() =>
                infoOpened ? removeParam('id') : setInfoOpened((v) => !v)
              }
              opened={infoOpened}
              title={data?.category?.title ?? ''}
              text=""
            />
            {infoOpened && (
              <ContentAlign>
                <BackButton
                  kind="transparent"
                  onClick={() => removeParam('id')}
                >
                  Powrót
                </BackButton>
                <MaxFadeIn>
                  <FullWidthGrid templateColumns="1fr 1.75fr" gap="large">
                    <Stack kind="vertical" spacing="extraLarge">
                      <AssetsProvider path={`/event/${data?.id ?? ''}`}>
                        {image && <MinHeightImageStyle img={image} />}
                      </AssetsProvider>
                      <Stack kind="vertical" divider={Divider}>
                        <Deadline>TERMIN</Deadline>
                        <Stack>
                          <span>{date}</span>
                          <span>{data?.startTime}</span>
                        </Stack>
                      </Stack>
                    </Stack>
                    <Scroll side="right" ref={scrollRef}>
                      <Stack kind="vertical" spacing="large">
                        <Title tag="h2">{data?.title}</Title>
                        <div>
                          <RichText value={data?.description ?? ''} />
                        </div>
                        <ReadMoreMargin onClick={() => setInfoOpened(false)}>
                          ZAPISZ SIĘ
                        </ReadMoreMargin>
                      </Stack>
                    </Scroll>
                  </FullWidthGrid>
                </MaxFadeIn>
              </ContentAlign>
            )}
            <ExpandMorePanel
              onClick={() => setInfoOpened((v) => !v)}
              opened={!infoOpened}
              title="ZAPISZ SIĘ ONLINE"
              text=""
            />
            {!infoOpened && (
              <FormContent>
                <NavFormWithConfirm
                  confirmed={confirmed}
                  text={t('event.form.success')}
                >
                  <MaxFadeIn>
                    <Stack kind="vertical" spacing="large">
                      <SignTitle>ZAPISZ SIĘ</SignTitle>
                      <div>
                        <RichText
                          value={`<F1>ZAPISUJESZ SIĘ NA:<F1>\n<F2>${
                            data?.title ?? ''
                          }<F2>`}
                        />
                      </div>
                      <StackCenter>
                        <span>{date}</span>
                        <span>{data?.startTime}</span>
                      </StackCenter>
                      <Form onSubmit={handleSubmit} contextOnly>
                        <TextInput name="name" label="Imię i nazwisko" />
                        <TextInput name="phone" label="Telefon" />
                        <TextInput type="email" name="email" label="E-mail" />
                        <StyledCheckboxInput
                          noSubmit
                          optional={false}
                          name="tos"
                          label="Zapoznał_m się z regulaminem zapisów i wyrażam zgodę na kontakt telefoniczny przez Poziom 511 Sp. z o.o. z siedzibą w Ogrodzieńcu w celach realizacji usług i sprzedaży produktów."
                        />
                        <StyledSubmit kind="transparent">
                          <Stack>
                            <span>OK</span>
                            <Arrow direction="right" length="7rem" />
                          </Stack>
                        </StyledSubmit>
                      </Form>
                    </Stack>
                  </MaxFadeIn>
                </NavFormWithConfirm>
              </FormContent>
            )}
          </>
        )}
      </WrapColor>
    </Context>
  )
}
