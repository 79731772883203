import { Image, mobile, styled, Text } from '@obvio/app'
import { FadeIn } from '@obvio/template'
import { Button, Grid, Stack } from '@obvio/ui'

import { Content, Wrap } from '../NavForm/components'
import { ExpandMorePanel } from '@/components/Nav/NavExpandMorePanel'

export const MaxFadeIn = styled(FadeIn)`
  height: 100%;
  padding-top: 5rem;
  @media ${mobile} {
    height: auto;
    padding-top: 0;
  }
`

export const MinHeightImageStyle = styled(Image)`
  position: relative;
  height: 100%;
  max-height: 50vh;
  width: 100%;
  min-width: 21.125rem;

  @media ${mobile} {
    min-height: 25rem;
    min-width: 100%;
  }
`

export const FullWidthGrid = styled(Grid)`
  width: 100%;
  grid-template-rows: minmax(0, 80vh);
  overflow: hidden;
  @media ${mobile} {
    grid-template-columns: 1fr;
    height: 100%;
    overflow: auto;
    > div {
      overflow: hidden;
    }
  }
`

export const ContentAlign = styled(Content)`
  text-align: left;
  padding: ${(theme) => theme.spacing.large};
  padding-top: 2rem;
  overflow: hidden;
  @media ${mobile} {
    overflow: auto;
    padding-top: 0;
  }
`

export const Title = styled(Text)`
  font-size: 1.5rem;
  font-weight: 300;
`

export const Divider = styled.div`
  height: 1px;
  width: 50%;
  background: ${(theme) => theme.colors.complementary};
`

export const Deadline = styled.span`
  font-size: 0.8125rem;
  color: ${(theme) => theme.colors.complementary};
`

export const WrapColor = styled(Wrap)`
  background: ${(theme) => theme.colors.additional[0]};
  @media ${mobile} {
    flex-direction: column;
  }
`

export const ExpandMorePanelColor = styled(ExpandMorePanel)`
  background: ${(theme) => theme.colors.additional[0]};
`

export const StackCenter = styled(Stack)`
  justify-content: center;
  font-size: 1.25rem;
`

export const FormContent = styled(Content)`
  padding: ${(theme) => theme.spacing.large};
  width: 100%;
  background: ${(theme) => theme.colors.complementary.semilight};
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow: auto;
  > div {
    max-width: 39.875rem;
    margin: 0 auto;
  }
`

export const BackButton = styled(Button)`
  height: auto;
  color: ${(theme) => theme.colors.secondary};
  @media ${mobile} {
    margin-bottom: ${(theme) => theme.spacing.extraLarge};
  }
`
