import { styled, Text } from '@obvio/app'
import { Stack } from '@obvio/ui'

import type { AllowUndefinedForOptional } from '@obvio/utils'
import type { ReactElement } from 'react'

const Wrap = styled(Stack)`
  ${(theme) => theme.flexCenter}
`

type NavFormConfirmedProps = AllowUndefinedForOptional<{
  text?: string
}>

export function NavFormConfirmed({
  text = 'Zapisałeś się na nasz newsletter. Do usłyszenia wkrótce.',
}: NavFormConfirmedProps): ReactElement {
  return (
    <Wrap kind="vertical">
      <Text tag="h2">DZIĘKUJEMY!</Text>
      <Text>{text}</Text>
    </Wrap>
  )
}
