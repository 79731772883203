import { styled } from '@obvio/app'
import { Submit, CheckboxInput } from '@obvio/ui'

export const StyledSubmit = styled(Submit)`
  color: ${(theme) => theme.colors.secondary};
  font-size: 2.25rem;
  font-family: ${(theme) => theme.secondaryFont};
  font-weight: 300;
  > div {
    align-items: center;
  }
`

export const StyledCheckboxInput = styled(CheckboxInput)`
  > span {
    margin-left: ${(theme) => theme.spacing.large};
    text-align: left;
    font-size: 0.75rem;
    color: ${(theme) => theme.colors.secondary.alpha(0.65)};
    position: static;
  }
`
