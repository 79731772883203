import { mobile, styled } from '@obvio/app'
import { SectionRenderer } from '@obvio/layout/renderer'
import { Grid, GridItem, Stack } from '@obvio/ui'
import { Fragment } from 'react'

import { CenteredSection } from './Renderer/CenteredSection'
import {
  buildRatiosGrid,
  customRenderers,
  primitiveRenderers,
  sortByOrder,
} from './Renderer/utils'

import type { Column, ElementControl, Row } from './Renderer/utils'
import type { StyleLevels } from '@obvio/app'
import type { RendererData } from '@obvio/layout/renderer'
import type { Spacing } from '@obvio/ui'
import type { AllowUndefinedForOptional } from '@obvio/utils'
import type { ReactElement } from 'react'

type RendererProps = {
  data: RendererData<any, any>[]
}

type SectionElementProps = {
  element: Row | Column
}

const MaxStack = styled(Stack)`
  width: 100%;
`

const FullGrid = styled(Grid)`
  width: 100%;
  @media ${mobile} {
    grid-template-columns: 1fr;
  }
`

// TODO: Check every page if this changes anything, maybe this wrapper is not needed
const FullGridItem = styled(GridItem)`
  height: 100%;
  width: 100%;
  overflow: hidden;
`

type ElementsWrapperTypes<Type extends 'row' | 'column'> =
  AllowUndefinedForOptional<{
    elements: Type extends 'row' ? Row[] : Column[]
    type: Type
    id: string
    control?: ElementControl
  }>

export function ElementsWrapper<Type extends 'row' | 'column'>({
  elements,
  type,
}: ElementsWrapperTypes<Type>): ReactElement {
  const gridDefinition = buildRatiosGrid(elements.sort(sortByOrder), type)

  return (
    <FullGrid
      {...(type === 'row'
        ? { templateRows: gridDefinition }
        : { templateColumns: gridDefinition, templateRows: 'max-content' })}
      gap="medium"
    >
      {elements.sort(sortByOrder).map(({ id: elementId, ...element }) => {
        return (
          <Fragment key={elementId}>
            <SectionElement element={{ id: elementId, ...element }} />
          </Fragment>
        )
      })}
    </FullGrid>
  )
}

export function SectionElement({
  element,
}: SectionElementProps): ReactElement | null {
  // handle direct component
  if ('type' in element) {
    return (
      <FullGridItem noCenter>
        {/** @ts-expect-error */}
        {primitiveRenderers[element.type](element)}
      </FullGridItem>
    )
  }

  if ('columns' in element || 'rows' in element) {
    const elements = (
      'columns' in element ? element.columns : (element as Column).rows
    ) as Column[] | Row[]
    return (
      <ElementsWrapper
        elements={elements}
        control={element.control}
        id={element.id}
        type={'columns' in element ? 'column' : 'row'}
      />
    )
  }

  return null
}

type ColumnsProps = {
  columns: Column[]
  spacing?: keyof StyleLevels
}

export function Columns({
  columns,
  spacing = 'medium',
}: ColumnsProps): ReactElement {
  return (
    <FullGrid
      className="column"
      templateColumns={buildRatiosGrid(columns, 'column')}
      gap={spacing}
    >
      {columns.sort(sortByOrder).map((column) => (
        <FullGridItem className="column-item" noCenter key={column.id}>
          <SectionElement element={column} key={column.id} />
        </FullGridItem>
      ))}
    </FullGrid>
  )
}

type RowsProps = {
  rows: Row[]
  spacing?: Spacing | undefined
}

export function Rows({ rows, spacing = 'medium' }: RowsProps): ReactElement {
  return (
    <MaxStack kind="vertical" spacing={spacing}>
      {rows.sort(sortByOrder).map((row) => {
        return <SectionElement key={row.id} element={row} />
      })}
    </MaxStack>
  )
}

export function Renderer({ data }: RendererProps): ReactElement {
  return (
    <>
      {data.map((section, index) => (
        <SectionRenderer
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          section={CenteredSection}
          customRenderers={customRenderers}
          data={section}
        />
      ))}
    </>
  )
}
